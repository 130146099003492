import { notificationStreamStatusEnum } from 'configuration/enums';
import { NOTIFICATIONS_STREAM_URL } from 'configuration/notificationsStreamUrl';
import { useDirectSelector } from 'hooks/useReactRedux';
import {
  getParsedDineInItemObject,
  getParsedDineInStatusObject,
  getParsedIncommingCallsObject,
  getParsedLayoutDividersObject,
  getParsedNonDineInObject,
  setStreamListeners,
  shouldParsedObjectBeUpdatedOrDeleted,
} from 'notifications/notificationUtils';
import { setIncomingCallsNotifications } from 'pages/Dashboard/action';
import React, { useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getOccupancyAPI } from 'services/dineInServices';
import { _auth } from '../../firebase';
import { DispatcherClient } from '../proto/notifications_grpc_web_pb';
import { NotificationRequest, Notifications } from '../proto/notifications_pb';
import { actionTypes } from '../reducer';
import { getFloorLayoutIndex } from '../utils';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getDineInObjectStatus, watchFloorLayoutChanges, getIncomingCalls } from 'notifications/firebase-listeners';

let token = '';

const client = new DispatcherClient(NOTIFICATIONS_STREAM_URL, null, null);
const request = new NotificationRequest();
const filterParam = new NotificationRequest.FilterParam();
const orginatedTime = new NotificationRequest.FilterParam();

// set the desired type of the notification
request.setNotificationTypesList([
  'dineInObjectStatus',
  'floorLayoutChanges',
  'incomingCalls',
]);

export const initConnection = async (storeId) => {
  if (!storeId) return null;
  try {
    filterParam.setParamName('storeId');
    filterParam.setStrValue(storeId);

    orginatedTime.setParamName('originatedTimeGte');
    orginatedTime.setIntValue(dayjs().valueOf());

    request.setFilterParamsList([filterParam, orginatedTime]);

    try {
      if (!token) {
        token = await _auth?.currentUser?.getIdToken(true);
      }
    } catch (err) {
      throw new Error(`Authorization error: ${err}`);
    }

    return client.getNotifications(request, {
      Authorization: `Bearer ${token}`,
    });
  } catch (err) {
    console.error('Stream Error: ', err);
    token = '';
    return false;
  }
};

export default function NotificationsProvider({ storeId, children }) {
  const stream = React.useRef();
  const hasAuthUser = _auth.currentUser;
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken
  );
  const updatedAccessToken = useRef(null);
  const [connectionStatus, setConnectionStatus] = React.useState(
    notificationStreamStatusEnum.IDLE
  );
  const { enableFirebaseListeners } = useFlags();

  /* REDUCER DISPATCHES */

  const createFloorLayout = (floorLayoutObject) =>
    dispatch({ type: actionTypes.createFloor, payload: floorLayoutObject });

  const addFloorLayoutObjects = (payload) =>
    dispatch({
      type: actionTypes.createFloorLayoutObjects,
      payload,
    });

  const changeFloorLayoutObjects = (payload) =>
    dispatch({
      type: actionTypes.updateFloorLayoutObjects,
      payload,
    });

  const removeFloorLayoutObject = (payload) =>
    dispatch({ type: actionTypes.deleteFloorLayoutObject, payload });

  const removeFloorLayout = (payload) =>
    dispatch({ type: actionTypes.deleteFloorLayout, payload });

  const resetNotificationsState = () =>
    dispatch({ type: actionTypes.resetNotificationsState });

  const addDineInObjectStatus = (payload) =>
    dispatch({ type: actionTypes.updateDineInObjectStatus, payload });

  const addDineInOccupanciestatus = (payload) =>
    dispatch({ type: actionTypes.updateDineInOccupanciesStatus, payload });

  useEffect(() => {
    if (accessToken && accessToken !== updatedAccessToken.current) {
      updatedAccessToken.current = accessToken;
    }
  }, [accessToken]);

  /* HANDLE FUNCTIONS */

  const handleConnectionStatus = React.useCallback((status) => {
    if (status === notificationStreamStatusEnum.CONNECTED) {
      resetNotificationsState();
    }
    setConnectionStatus(status);
    // eslint-disable-next-line
  }, []);

  const handleLayoutResponse = React.useCallback((responseObj) => {
    const floorLayoutResponse = responseObj.getFloorLayoutChangesList();
    batch(() => {
      floorLayoutResponse.forEach((layout) => {
        const floorLayoutOperationType = layout.getOperationType();

        const floorName = layout.getName();
        const floorLayoutInfo = {
          id: layout.getId(),
          isActive: layout.getIsActive(),
          displayOrder: layout.getDisplayOrder(),
          ...(floorName ? { name: floorName } : null),
        };
        switch (floorLayoutOperationType) {
          case Notifications.OperationType.CREATED: {
            if (!floorLayoutInfo.isActive) break;
            createFloorLayout(floorLayoutInfo);
            break;
          }
          case Notifications.OperationType.UPDATED: {
            if (!floorLayoutInfo.isActive) break;
            changeFloorLayoutObjects({
              floorLayoutInfo,
              objectListsToAdd: {},
            });
            break;
          }
          case Notifications.OperationType.NO_CHANGE: {
            if (!floorLayoutInfo.isActive) break;
            let hasObjectUpdated = false;
            let isObjectToBeDeleted = false;
            const objectListsToAdd = {};
            const persistingFloorLayoutInfo = {
              id: layout.getId(),
            };

            const nonDineInObjectsResponse = layout?.getNonDineInObjectsList();
            const nonDineInObjects = nonDineInObjectsResponse.map(
              (nonDineInItem) => {
                const { shouldUpdate, shouldDelete } =
                  shouldParsedObjectBeUpdatedOrDeleted(nonDineInItem);
                hasObjectUpdated = shouldUpdate;
                isObjectToBeDeleted = shouldDelete;
                return getParsedNonDineInObject(nonDineInItem);
              }
            );
            if (isObjectToBeDeleted) {
              removeFloorLayoutObject({
                floorLayoutInfo: persistingFloorLayoutInfo,
                floorLayoutObjectType: 'nonDineInObjects',
                objectToBeRemoved: nonDineInObjects[0],
              });
              break;
            }
            if (nonDineInObjects.length)
              objectListsToAdd.nonDineInObjects = nonDineInObjects;
            const layoutDividersResponse = layout.getLayoutDividersList();
            const layoutDividers = layoutDividersResponse?.map(
              (layoutDividersItem) => {
                const { shouldUpdate, shouldDelete } =
                  shouldParsedObjectBeUpdatedOrDeleted(layoutDividersItem);
                hasObjectUpdated = shouldUpdate;
                isObjectToBeDeleted = shouldDelete;
                return getParsedLayoutDividersObject(layoutDividersItem);
              }
            );
            if (isObjectToBeDeleted) {
              removeFloorLayoutObject({
                floorLayoutInfo: persistingFloorLayoutInfo,
                floorLayoutObjectType: 'layoutDividers',
                objectToBeRemoved: layoutDividers[0],
              });
              break;
            }
            if (layoutDividers.length)
              objectListsToAdd.layoutDividers = layoutDividers;
            const dineInObjectsResponse = layout.getDineInObjectsList();
            const dineInObjects = dineInObjectsResponse?.map((dineInItem) => {
              const { shouldUpdate, shouldDelete } =
                shouldParsedObjectBeUpdatedOrDeleted(dineInItem);
              hasObjectUpdated = shouldUpdate;
              isObjectToBeDeleted = shouldDelete;
              return getParsedDineInItemObject(dineInItem);
            });
            if (isObjectToBeDeleted) {
              removeFloorLayoutObject({
                floorLayoutInfo: persistingFloorLayoutInfo,
                floorLayoutObjectType: 'dineInObjects',
                objectToBeRemoved: dineInObjects[0],
              });
              break;
            }
            if (dineInObjects.length)
              objectListsToAdd.dineInObjects = dineInObjects;

            if (hasObjectUpdated) {
              changeFloorLayoutObjects({
                floorLayoutInfo: persistingFloorLayoutInfo,
                objectListsToAdd,
              });
              break;
            }
            addFloorLayoutObjects({
              floorLayoutInfo: persistingFloorLayoutInfo,
              objectListsToAdd,
            });
            break;
          }
          case Notifications.OperationType.DELETED: {
            removeFloorLayout({ floorLayoutInfo });
            break;
          }
          default: {
            console.log(
              '%c 🍏 oh no ',
              'font-size:20px;background-color: #4b4b4b;color:#fff;'
            );
          }
        }
      });
    });

    // eslint-disable-next-line
  }, []);

  const fetchingDineInOccupancies = useRef(false);
  const fetchDineInOccupanciesResponse = async () => {
    if (fetchingDineInOccupancies.current) return;
    try {
      fetchingDineInOccupancies.current = true;

      const { data } = await getOccupancyAPI({
        storeId,
        accessToken: updatedAccessToken.current,
      });
      addDineInOccupanciestatus(data.dineInOccupancies ?? []);
    } catch (err) {
      console.error('Error in fetching DineInOccupancies', err);
    }
    fetchingDineInOccupancies.current = false;
  };

  const handleDineInResponse = React.useCallback(
    (responseObj) => {
      fetchDineInOccupanciesResponse();
      const dineInResponse = responseObj.getDineInObjectStatusesList();
      const dineInObjects = dineInResponse.map((item) =>
        getParsedDineInStatusObject(item)
      );
      if (dineInObjects.length) addDineInObjectStatus(dineInObjects);
      else addDineInObjectStatus([]);
    },
    // eslint-disable-next-line
    [storeId, accessToken]
  );

  const handleIncomingCallsResponse = (responseObj) => {
    const incomingCallsResponse = responseObj.getIncomingCallChangesList();

    const incomingCallsObjects = incomingCallsResponse.map((item) =>
      getParsedIncommingCallsObject(item)
    );
    dispatch(setIncomingCallsNotifications(incomingCallsObjects));
  };

  const handleConnection = React.useCallback(() => {
    const getStream = async () => {
      setConnectionStatus(notificationStreamStatusEnum.CONNECTING);
      stream.current = await initConnection(storeId);
      if (stream.current) {
        console.log(`zzz grpc`)
        handleConnectionStatus(notificationStreamStatusEnum.CONNECTED);
        const notificationHandlers = {
          floorLayoutChanges: handleLayoutResponse,
          dineInObjectStatus: handleDineInResponse,
          incomingCalls: handleIncomingCallsResponse,
        };
        setStreamListeners(
          stream.current,
          notificationHandlers,
          handleConnectionStatus
        );
      } else {
        setConnectionStatus(notificationStreamStatusEnum.DISCONNECTED);
      }
    };
    if (
      hasAuthUser &&
      storeId &&
      (connectionStatus === notificationStreamStatusEnum.IDLE ||
        connectionStatus === notificationStreamStatusEnum.DISCONNECTED)
    ) {
      token = '';
      getStream();
    }
    // eslint-disable-next-line
  }, [
    handleConnectionStatus,
    handleDineInResponse,
    handleLayoutResponse,
    connectionStatus,
    hasAuthUser,
    storeId,
  ]);

  // React.useEffect(() => {
  //   handleConnection();
  // }, [handleConnection]);

  React.useEffect(() => {
    if (storeId &&!enableFirebaseListeners) {
      console.log(`zzz grpc`);
      handleConnection();
    }
  }, [handleConnection, enableFirebaseListeners]);

  React.useEffect(() => {
    if (storeId && enableFirebaseListeners) {
      console.log(`zzz firebase`);
      const currentTime = dayjs().valueOf();
      dispatch(getDineInObjectStatus(storeId, fetchDineInOccupanciesResponse));
      dispatch(getIncomingCalls(storeId, currentTime));
      watchFloorLayoutChanges(storeId, dispatch);
    }
  }, [storeId, enableFirebaseListeners]);

  return <>{children}</>;
}

export const useDineInObjects = () => {
  const context = useDirectSelector('notificationsReducer');

  return {
    dineInObjects: context.dineInObjects,
    totalNumPeople: context.totalNumPeople,
  };
};

export const useGetFloorName = (floorLayoutId) => {
  const context = useDirectSelector('notificationsReducer');

  const { floorLayouts } = context;
  const floorIndex = getFloorLayoutIndex(floorLayouts, floorLayoutId);
  return floorLayouts[floorIndex]?.name || '';
};

export const useFloorLayouts = () => {
  const context = useDirectSelector('notificationsReducer');

  const sortedFloorLayouts = React.useMemo(() => {
    const floorLayouts = [...context.floorLayouts];
    return floorLayouts?.sort(
      (floorA, floorB) => floorA.displayOrder - floorB.displayOrder
    );
  }, [context.floorLayouts]);
  return [sortedFloorLayouts];
};
