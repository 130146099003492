import { setIncomingCallsNotifications } from 'pages/Dashboard/action';
import { _firestore } from '../firebase';
import { actionTypes } from './reducer';
import { seatingStatuses, floorLayoutOperationTypes, objectTypes } from './constants';

export function getDineInObjectStatus(storeId, callback) {
  return (dispatch) => {
    _firestore
      .collection('DineInObjectStatuses')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const transformedData = data.map((item) => {
          return {
            dineInObjectId: item.dineInObjectId,
            isOccupied: item.isOccupied,
            occupancyId: item.currentOccupancy,
            status: seatingStatuses[item.status],
            currentOrderId: item.currentOrder,
          };
        });
        dispatch({ type: actionTypes.updateDineInObjectStatus, payload: transformedData });
        if(callback) callback();
      });
  }
}

export function getIncomingCalls(storeId, currentTime) {
  return (dispatch) => {
    _firestore
      .collection('IncomingCalls')
      .where('storeId', '==', storeId)
      .where('originatedTime', '>=', currentTime)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const transformedData = data.map((item) => {
          return {
            id: item.id,
            lineId: item.lineId,
            lineName: item.lineName,
            originatedTime: item.originatedTime,
            phone: item.phone,
            status: item.status,
            restaurantId: item.restaurantId,
            storeId: item.storeId
          }
        });
        dispatch(setIncomingCallsNotifications(transformedData));
      });
  }
}

// listener for floorLayouts
export function watchFloorLayoutChanges(storeId, dispatch) {
  _firestore
    .collection('FloorLayouts')
    .where('storeId', '==', storeId)
    .onSnapshot(async (querySnapshot) => {
      for(const change of querySnapshot.docChanges()) {
        const changeData = change.doc.data();

        const floorLayoutInfo = {
          id: change.doc.id,
          isActive: changeData.isActive,
          displayOrder: changeData.displayOrder,
        };
        if(changeData.name) {
          floorLayoutInfo.name = changeData.name;
        }
        processFloorLayout(floorLayoutInfo, change.type, dispatch);
        if(changeData.isActive && change.type !== floorLayoutOperationTypes.DELETED) {
          watchFloorObjects(change.doc, 'dineInObjects', dispatch);
          watchFloorObjects(change.doc, 'nonDineInObjects', dispatch);
          watchFloorObjects(change.doc, 'layoutDividers', dispatch);
        }
      }
    });
}

// dispatcher for floorLayouts
function processFloorLayout(floorLayoutInfo, changeType, dispatch) {
  switch(changeType) {
    case floorLayoutOperationTypes.CREATED: {
      dispatch({ type: actionTypes.createFloor, payload: floorLayoutInfo });
      break;
    }
    case floorLayoutOperationTypes.UPDATED: {
      if(!floorLayoutInfo.isActive) break;
      dispatch({
        type: actionTypes.updateFloorLayoutObjects,
        payload: {
          floorLayoutInfo,
          objectListsToAdd: {},
        },
      });
      break;
    }
    case floorLayoutOperationTypes.DELETED: {
      dispatch({ type: actionTypes.deleteFloorLayout, payload: floorLayoutInfo });
      break;
    }
    default: {
      console.log(
        '%c 🍏 oh no ',
        'font-size:20px;background-color: #4b4b4b;color:#fff;'
      );
    }
  }
}

// listener for dineinobjects, nonDineinobjects, layoutDividers within a floorLayout
function watchFloorObjects(doc, type, dispatch) {
  doc.ref.collection(type).onSnapshot((snapshot) => {
    if(snapshot.docChanges().length === 0) return;
    const layoutId = doc.id;
    const persistingFloorLayoutInfo = {
      id: layoutId,
    };
    const objectListsToAdd = {};

    // initial load, all objects added at once
    if(snapshot.docChanges().every((change) => change.type === floorLayoutOperationTypes.CREATED)) {
      objectListsToAdd[type] = snapshot.docChanges().map(
        (change) => (
          {
            id: change.doc.id,
            ...change.doc.data(),
            type: objectTypes[type][change.doc.data().type]
          }
        )
      );
      dispatch({
        type: actionTypes.createFloorLayoutObjects,
        payload: { floorLayoutInfo: persistingFloorLayoutInfo, objectListsToAdd },
      });
      return;
    }
    
    for(const change of snapshot.docChanges()) {
      const currentItem = {
        id: change.doc.id,
        ...change.doc.data(),
        type: objectTypes[type][change.doc.data().type]
      }
      let hasObjectUpdated = change.type === floorLayoutOperationTypes.UPDATED;
      let isObjectToBeDeleted = change.type === floorLayoutOperationTypes.DELETED;

      if(isObjectToBeDeleted) {
        dispatch({
          type: actionTypes.deleteFloorLayoutObject,
          payload: {
            floorLayoutInfo: persistingFloorLayoutInfo,
            floorLayoutObjectType: type,
            objectToBeRemoved: currentItem,
          },
        });
        // breaking from the loop because only 1 change is expected at a time
        break;
      }

      if(snapshot.docChanges().length) {
          objectListsToAdd[type] = snapshot.docChanges().map(
            (change) => (
              {
                id: change.doc.id,
                ...change.doc.data(),
                type: objectTypes[type][change.doc.data().type]
              }
            )
          );
      }

      if(hasObjectUpdated) {
        dispatch({
          type: actionTypes.updateFloorLayoutObjects,
          payload: { floorLayoutInfo: persistingFloorLayoutInfo, objectListsToAdd },
        });
        break;
      }
      dispatch({
        type: actionTypes.createFloorLayoutObjects,
        payload: { floorLayoutInfo: persistingFloorLayoutInfo, objectListsToAdd },
      });
    }
  });
}