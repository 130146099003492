import { PosIconStore } from 'assets/icons/PosIconStore';
import { PosIconTable } from 'assets/icons/PosIconTable';
import classNames from 'classnames';
import { ModalPortal } from 'components';
import { useGetFloorName } from 'notifications/context/NotificationsState';
import TimeModal from 'modals/TimeModal';
import { getOrderTimeArray } from '../../../util/getTimeSlots';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEstimatedTime } from 'util/dateHelper';
import { storeConfigSelector } from '../../../pages/Dashboard/selectors';
import { SingleOrderList } from '../../OrderBar/SingleOrderList';
import { UberIcon } from '../../../assets/icons/UberIcon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import BottomBar from './BottomBar';
import clsx from 'clsx';
import { PlusCircleIcon, XRoundedIcon } from 'nexticons/solid';
import * as actions from 'pages/Dashboard/action';
import SelectDeliveryMethodModal from './SelectDeliveryMethodModal/SelectDeliveryMethodModal';
import SelectDriverModal from './SelectDriverModal/SelectDriverModal';
import RemoveDriverModal from './RemoveDriverModal';
import { getUberDeliveryDetails } from 'components/UberDriverModal/UberDriverModal.service.ts';
import { statusHeadingMap } from 'components/UberDriverModal/UberDriverModal.tsx';
import { LoadingIcon } from 'nexticons/outline';

dayjs.extend(relativeTime);

const paymentStatusMap = {
  0: `unpaid`,
  1: `paid`,
  2: `partially-paid`,
  11: `cancelled`,
};

const paymentTypeMap = {
  1: `cash`,
  2: `card`,
  3: `card`,
};

const orderTypeMap = {
  0: 'walkin',
  1: 'pickup',
  2: 'delivery',
  3: 'dinein',
};

const orderStatusMapping = {
  '-1': 'Failed',
  0: 'Unconfirmed',
  1: 'Placed',
  2: 'In Kitchen',
  3: 'On the way',
  4: 'Delivered',
  11: 'Canceled',
  25: 'Out For Delivery',
};

const ReadyOrderView = ({ selectedOrderId }) => {
  const dispatch = useDispatch();
  const selectedOrder = useSelector(
    (state) => state.newOrdersReducer.orders.allOrdersMap[selectedOrderId] || {}
  );
  const isUberDriveEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.isUberDriveEnabled
  );
  const scrolledStatus = useSelector(
    (state) => state.ordersReducer.scrolledStatus
  );
  const storeConfig = useSelector(storeConfigSelector);
  const orderSetup = useSelector((s) => s.dashboardReducer.orderSetup);
  const driversList = useSelector((s) => s.driversReducer.drivers);
  const driverDetails = driversList?.find(
    (driver) => driver.driverId === selectedOrder?.driverId
  );
  const shifts = useSelector((s) => s.dashboardReducer.shifts);
  const shadowCls = classNames({ shadow: scrolledStatus[7].position });
  const {
    deliveryDateTimestamp,
    paymentStatus,
    floorLayoutId,
    paymentType,
    orderType,
  } = selectedOrder;

  const floorName = useGetFloorName(floorLayoutId);

  const orderStatus = paymentStatusMap[paymentStatus];
  const orderPayment = paymentTypeMap[paymentType];
  const _orderType = orderTypeMap[orderType];

  const { estimatedTimeInMins, estimatedTime } = getEstimatedTime(
    deliveryDateTimestamp
  );

  const timeFromNow = dayjs(selectedOrder.deliveryDateTimestamp).fromNow();

  const [deliveryTimes, setDeliveryTimes] = useState([]);
  const [openSelectDeliveryMethodModal, setOpenSelectDeliveryMethodModal] =
    useState(false);

  const [openSelectDriverModal, setOpenSelectDriverModal] = useState(false);

  const [openRemoveDriverModal, setOpenRemoveDriverModal] = useState(false);

  const [uberRideDetail, setUberRideDetail] = useState({});
  const [isUberApiLoading, setIsUberApiLoading] = useState(false);

  const handleSelectDeliveryMethodModalClose = () => {
    dispatch(actions.toggleBlurEffect(false));
    setOpenSelectDeliveryMethodModal(false);
  };

  const handleSelectDriverdModalClose = () => {
    dispatch(actions.toggleBlurEffect(false));
    setOpenSelectDriverModal(false);
  };

  const handleRemoveDriverModalClose = () => {
    dispatch(actions.toggleBlurEffect(false));
    setOpenRemoveDriverModal(false);
  };

  const getInitialsAlphabets = (firstName, lastName) => {
    const firstInitial = firstName?.charAt(0) || '';
    const lastInitial = lastName?.charAt(0) || '';
    return (firstInitial + lastInitial)?.toUpperCase() || '';
  };

  const renderDriverSection = () => {
    if (selectedOrder.driverId && !selectedOrder?.uberDriveDeliveryCreated) {
      return (
        <div className={clsx('order-status-box', 'rightPositioned')}>
          <div className="assign-driver-details">
            <div className="assignDriverAvatar">
              <span className="assignDriverAvatarText">
                {getInitialsAlphabets(
                  driverDetails?.firstName,
                  driverDetails?.lastName
                )}
              </span>
            </div>
            <span className="assign-driver-details-button-text">
              {driverDetails?.firstName} {driverDetails?.lastName}
            </span>
          </div>

          {selectedOrder?.orderStatus === '2' && (
            <button
              className="remove-driver-button"
              onClick={() => setOpenRemoveDriverModal(true)}
            >
              <div>
                <XRoundedIcon
                  width={5}
                  strokeWidth={1}
                  className="remove-driver-button-icon"
                />
              </div>

              <span className="assign-driver-button-text">Remove Driver</span>
            </button>
          )}
        </div>
      );
    }

    if (selectedOrder?.uberDriveDeliveryCreated) {
      return (
        <div className={clsx('order-status-box', 'uberMaxWidht')}>
          <div className="uber-driver-details">
            <div className="uber-Icon">
              <UberIcon />
            </div>

            {isUberApiLoading ? (
              <div className="loading-Icon">
                <LoadingIcon width={30} />
              </div>
            ) : (
              <>
                <span className="uber-driver-details-button-text">
                  {statusHeadingMap[uberRideDetail?.status]}
                </span>
                {!['delivered', 'canceled', 'returned', 'pending'].includes(
                  uberRideDetail?.status
                ) && (
                  <button
                    className="track-button"
                    onClick={() =>
                      window.open(
                        uberRideDetail?.trackingUrl,
                        '_blank',
                        'noreferrer'
                      )
                    }
                  >
                    <span className="track-button-text">Track</span>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className={clsx('order-status-box', 'rightPositioned')}>
        <button
          className="assign-driver-button"
          onClick={() => setOpenSelectDeliveryMethodModal(true)}
        >
          <PlusCircleIcon
            strokeWidth={2}
            width={28.1}
            height={30}
            color="#067aff"
          />
          <span className="assign-driver-button-text">Assign Driver</span>
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (!deliveryTimes.length > 0 && shifts && orderSetup) {
      let deliveryTimesData = getOrderTimeArray(
        orderSetup ? orderSetup : {},
        storeConfig,
        '2'
      );
      setDeliveryTimes(deliveryTimesData);
    }
  }, []);

  useEffect(() => {
    if (!deliveryTimes.length > 0 && shifts && orderSetup) {
      let deliveryTimesData = getOrderTimeArray(
        orderSetup ? orderSetup : {},
        storeConfig,
        '2'
      );
      setDeliveryTimes(deliveryTimesData);
    }
  }, []);

  useEffect(async () => {
    if (selectedOrder?.uberDriveDeliveryCreated) {
      setIsUberApiLoading(true);
      const uberResponse = await getUberDeliveryDetails(selectedOrder?._id);

      if (uberResponse?.code === 200) {
        setUberRideDetail(uberResponse?.data?.delivery);
      }
      setIsUberApiLoading(false);
    }
  }, [JSON.stringify(selectedOrder)]);

  return (
    <>
      {!(_orderType === `walkin`) ? (
        <div className="guest-location-row">
          <div className="location-box">
            <span className="icon-box">
              {floorLayoutId ? (
                <PosIconTable mainColor="#494B4C" />
              ) : _orderType === `pickup` ? (
                <PosIconStore mainColor="#494B4C" />
              ) : (
                <span className="dine_icon-home" />
              )}
            </span>
            <span className="desc">
              {floorLayoutId
                ? `Table ${selectedOrder?.tableNumber || ''}, ${floorName}`
                : _orderType === `pickup`
                ? 'Pickup'
                : selectedOrder.address}
            </span>
          </div>
        </div>
      ) : null}

      <div className={'order-status-row ' + shadowCls}>
        <div
          className={clsx('order-status-box', {
            ['removeSeparator']:
              !selectedOrder.driverId &&
              !selectedOrder?.uberDriveDeliveryCreated,
            ['maxWidht']: selectedOrder?.orderType !== '2',
          })}
        >
          <span className="icon-box">
            <span className="dine_icon-clock" />
          </span>
          <span className="desc">
            {deliveryDateTimestamp ? (
              <>
                <span
                  className={
                    estimatedTimeInMins === 0
                      ? 'time-from-now'
                      : 'estimated-time'
                  }
                >
                  {`Expected ${timeFromNow} `}
                </span>
                <span>{` - ${estimatedTime} `}</span>
                <span>{` - ${
                  orderStatusMapping[selectedOrder?.orderStatus]
                } `}</span>
              </>
            ) : (
              //selectedOrder.textStatus.secondText
              ''
            )}
            {/* &nbsp;- 7:34pm */}
          </span>
        </div>

        {_orderType === 'delivery' && renderDriverSection()}
      </div>

      <SingleOrderList
        showPrice={true}
        currentOrder={selectedOrder}
        basePath={''}
        fullScreen={true}
      />

      <BottomBar
        {...selectedOrder}
        orderStatus={orderStatus}
        orderPayment={orderPayment}
        orderType={_orderType}
      />

      <ModalPortal>
        <TimeModal selectedOrder={selectedOrder} />
      </ModalPortal>

      {openSelectDeliveryMethodModal && (
        <ModalPortal>
          <SelectDeliveryMethodModal
            toggleModal={handleSelectDeliveryMethodModalClose}
            setOpenSelectDriverModal={setOpenSelectDriverModal}
            isUberDriveEnabled={isUberDriveEnabled}
          />
        </ModalPortal>
      )}

      {openSelectDriverModal && (
        <ModalPortal>
          <SelectDriverModal
            toggleModal={handleSelectDriverdModalClose}
            selectedOrder={selectedOrder}
          />
        </ModalPortal>
      )}

      {openRemoveDriverModal && (
        <ModalPortal>
          <RemoveDriverModal
            modalStatus={openRemoveDriverModal}
            toggleModal={handleRemoveDriverModalClose}
            driverName={` ${driverDetails?.firstName} ${driverDetails?.lastName}`}
            driverId={driverDetails?.driverId || ''}
            selectedOrder={selectedOrder}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default ReadyOrderView;
