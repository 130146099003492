export const seatingStatuses = {
  0: 'UnknownDineInObjectStatusVal',
  1: 'PartiallySeated',
  2: 'Seated',
  3: 'MainCourse',
  4: 'Dessert',
  5: 'Cleared',
  6: 'CheckDropped',
  7: 'Paid',
  8: 'BussingNeeded',
  9: 'Finished',
};

export const floorLayoutOperationTypes = {
  UNKNOWN: 0,
  CREATED: 'added',
  UPDATED: 'modified',
  DELETED: 'removed',
  NO_CHANGE: 'noChange'
};

export const objectTypes = {
  nonDineInObjects: {
    0: 'UnknownNonDineInObjectType',
    1: 'PottedPlant',
    2: 'Piano',
    3: 'Staircase',
  },
  dineInObjects: {
    0: 'UnknownDineInObjectType',
    1: 'SquareTable',
    2: 'RectangularTable',
    3: 'RoundTable',
    4: 'RhombusTable',
  }
}