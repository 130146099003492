import './WithdrawalDetailModal.scss';
import GeneralModal from '../../../modals/GeneralModal/index-new';
import { BasePartUI, ButtonUI, InputUI } from '../../../UI/components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  storeConfigSelector,
  isAuthDisabledForPOSSelector,
} from '../../../pages/Dashboard/selectors';
import { useVirtualKeyboardContext } from '../../../context/VirtualKeyboardContext';
import { recordWithdrawal } from './WithdrawalDetailModal.service';

const getInitialsAlphabets = (firstName, lastName) => {
  const firstInitial = firstName?.charAt(0) || '';
  const lastInitial = lastName?.charAt(0) || '';
  return firstInitial + lastInitial;
};

export const WithdrawalDetailModal = ({
  show,
  toggleModal,
  amount,
  currencySymbol,
  cashDrawerId,
  onBackClick,
}) => {
  const storeConfig = useSelector(storeConfigSelector);
  const isAuthDisabledForPOS = useSelector(isAuthDisabledForPOSSelector);
  const { restaurantId, storeId, countryIdentifier } = storeConfig;
  const activeStaffMember = useSelector(
    (state) => state.dashboardReducer?.activeStaffMember
  );
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');

  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    onInitInput,
  } = useVirtualKeyboardContext();

  const descriptionValue = getInputValue('description');

  useEffect(() => {
    onInitInput('description', '');
  }, []);

  useEffect(() => {
    setDescription(descriptionValue || '');
  }, [descriptionValue]);

  const onModalClose = () => {
    toggleModal();
    setDescription('');
  };

  const handleOnFocus = (event, fieldName) => {
    setInputName(fieldName);
    toggleKeyboard(true);
  };

  const handleRecordWithdrawal = async () => {
    setLoading(true);
    const reqObj = {
      amount: Number(amount),
      authorizedByUserId: activeStaffMember?._id || '',
      authorizedByUserName: activeStaffMember?.firstName
        ? `${activeStaffMember?.firstName} ${activeStaffMember?.lastName}`
        : '',
      cashDrawerId: cashDrawerId,
      description: description,
      restaurantId: restaurantId,
      storeId: storeId,
    };

    const response = await recordWithdrawal(reqObj, cashDrawerId);
    if (response.success) {
      onModalClose();
    }
    setLoading(false);
  };

  return (
    <>
      <GeneralModal
        className="bem-withdrawalDetail-modal"
        modalStatus={show}
        toggleModal={onModalClose}
        position={'center'}
        effect="fade"
        overlay={'default'}
        boxWidth="435px"
        boxHeight="536px"
        backBtn="outside"
        backBtnFnc={onBackClick}
        isResponsiveHeight
        keyboardVisibility={keyboardVisibility}
      >
        <form
          action=""
          style={{
            display: 'flex',
            height: '100%',
          }}
        >
          <BasePartUI>
            <BasePartUI.Header>
              <h3 className="bem-withdrawalDetail-modal__title">
                Withdrawal Details
              </h3>
            </BasePartUI.Header>
            <BasePartUI.Body scrollShadow>
              <InputUI
                name="description"
                label="Widthdrawal Description"
                labelRequired
                disabled={loading}
                value={description}
                onFocus={(event) => handleOnFocus(event, 'description')}
                onChange={(event) => {
                  setDescription(event.target.value);
                  onChangeInput(event.target.value);
                }}
                containerStyle={{ paddingBottom: 8 }}
              />

              <div>
                <div className="bem-amount">Amount</div>
                <div className="item-border">
                  {currencySymbol}
                  {amount}
                </div>
              </div>

              {!isAuthDisabledForPOS && activeStaffMember?._id && (
                <div className="authorisedby-container">
                  <div className="bem-amount">Authorised by</div>
                  <div className="item-border">
                    <div className="authorisedby-avatar">
                      {getInitialsAlphabets(
                        activeStaffMember?.firstName,
                        activeStaffMember?.lastName
                      ).toUpperCase()}
                    </div>
                    <div>
                      {activeStaffMember?.firstName}{' '}
                      {activeStaffMember?.lastName}
                    </div>
                  </div>
                </div>
              )}
            </BasePartUI.Body>
            <BasePartUI.Footer>
              <ButtonUI
                type="submit"
                style={{ height: 57 }}
                styleType="success"
                sizeType="xl"
                rounded="none"
                fullWidth
                onClick={handleRecordWithdrawal}
                loading={loading}
                disabled={!description}
              >
                Record Widthdrawal
              </ButtonUI>
            </BasePartUI.Footer>
          </BasePartUI>
        </form>
      </GeneralModal>
    </>
  );
};
